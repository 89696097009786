<template>
    <div class="list-wrapper">
        <div class="summary flex items-center">
            <div 
                class="mr-4 text-sm">
                Всего: {{ summaryTotal }}
            </div>
            <div 
                class="summary_item bg_white"
                :class="summaryActive['white'] && 'active'"
                @click="setSummaryFilters('white')">
                <div class="bg"></div>
                <span>{{ summaryWhite }}</span>
            </div>
            <div 
                class="summary_item bg_yellow"
                :class="summaryActive['yellow'] && 'active'"
                @click="setSummaryFilters('yellow')">
                <div class="bg"></div>
                <span>{{ summaryYellow }}</span>
            </div>
            <div 
                class="summary_item bg_orange"
                :class="summaryActive['orange'] && 'active'"
                @click="setSummaryFilters('orange')">
                <div class="bg"></div>
                <span>{{ summaryOrange }}</span>
            </div>
            <div 
                class="summary_item bg_red"
                :class="summaryActive['red'] && 'active'"
                @click="setSummaryFilters('red')">
                <div class="bg"></div>
                <span>{{ summaryRed }}</span>
            </div>
        </div>
        <div class="ordering">
            <a-select
                v-model="ordering"
                allowClear
                class="select"
                placeholder="Сортировка"
                @change="handleOrderingChange">
                <a-select-option v-for="option, index in sortOptions" :key="index" :value="option.value">
                    {{ option.label }}
                </a-select-option>
            </a-select>
        </div>
        <div class="organization-filter">
            <a-radio-group v-model="organizationsFilter" @change="handleOrganizationsFilterChange">
                <a-radio value="only_my">
                    Организация
                </a-radio>
                <a-radio value="all">
                    Со структурными подразделениями
                </a-radio>
                <a-radio value="descendants">
                    Структурные подразделения
                </a-radio>
            </a-radio-group>
        </div>
        <div class="filters check_button">
            <a-checkbox
                :checked="filters['new_inquiries']"
                @change="newInquiriesFilterHandler($event, 'new_inquiries')">
                Новые
            </a-checkbox>
            <a-checkbox
                :checked="filters['processed_inquiries']"
                @change="processedInquiriesFilterHandler($event, 'processed_inquiries')">
                Обработанные
            </a-checkbox>
            <a-checkbox
                :checked="filters['current_month']"
                @change="currentMonthFilterHandler($event, 'current_month')">
                Текущий месяц
            </a-checkbox>
            <a-checkbox
                :checked="filters['current_year']"
                @change="currentYearFilterHandler($event, 'current_year')">
                Текущий год
            </a-checkbox>
            <a-checkbox
                :checked="filters['period']"
                @change="periodHandler($event, 'period')">
                Период
            </a-checkbox>
        </div>
        <div v-show="!setPeriodDisabled" class="set_period">
            <a-date-picker
                class="w-full"
                v-model="periodStart"
                :disabled="setPeriodDisabled"
                :getPopupContainer="trigger => trigger.parentElement"
                placeholder="Начало периода"
                format="DD.MM.YYYY"
                @change="periodChange" />
            <a-date-picker
                class="w-full"
                v-model="periodEnd"
                :disabled="setPeriodDisabled"
                :getPopupContainer="trigger => trigger.parentElement"
                placeholder="Конец периода"
                format="DD.MM.YYYY"
                @change="periodChange" />
        </div>
        <div class="inquiries-list">
            <MobileInquirItem
                v-for="assessment in list"
                :key="assessment.id"
                :assessment="assessment"
                class="mb-5 border custom_border_color overflow-hidden rounded-lg" />
        </div>
        <infinite-loading
            :ref="getRef"
            :identifier="infiniteId"
            @infinite="getList"
            :distance="10">
            <div 
                slot="spinner"
                class="mt-[30px]">
                <a-spin />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
        <div 
            v-if="showEmpty" 
            class="pt-8">
            <a-empty />
        </div>
        <div class="float_add">
            <slot name="viewButton" />
            <div class="filter_slot">
                <PageFilter 
                    :model="model"
                    :key="name"
                    :name="name"
                    size="large"
                    :page_name="page_name" />
            </div>
            <a-button
                v-if="showAddButton"
                flaticon
                shape="circle"
                size="large"
                type="primary"
                icon="fi-rr-plus"
                @click="newInquir" />
        </div>
    </div>
</template>

<script>
import MobileInquirItem from "./MobileInquirItem.vue"
import InfiniteLoading from 'vue-infinite-loading'
import methodsM from "./mixins/methods"
import eventBus from '@/utils/eventBus'
import PageFilter from '@/components/PageFilter'

export default {
    name: 'InquiriesList',
    components: {
        MobileInquirItem,
        InfiniteLoading,
        PageFilter
    },
    mixins: [
        methodsM,
    ],
    props: {
        page_name: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        model: {
            type: String,
            default: ''
        },
        showAddButton: {
            type: Boolean,
            default: () => false
        },
        newInquir: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            filters: {
                current_month: false,
                current_year: false,
                period: false,
                processed_inquiries: false,
                new_inquiries: false,
                organizations: false
            },
            infiniteId: new Date(),
            showEmpty: false,
            report: {},
            loading: false,
            list: [],
            page: 1,
            pageSize: 5,
            count: 0,
            periodStart: null,
            periodEnd: null,
            organizationsFilter: null,
            sortOptions: [
                {
                    label: 'По номеру А..я',
                    value: 'issue__number'
                },
                {
                    label: 'По номеру Я..а',
                    value: '-issue__number'
                },
                {
                    label: 'Сначала новые',
                    value: '-issue__issue_date'
                },
                {
                    label: 'Сначала старые',
                    value: 'issue__issue_date'
                }
            ],
            ordering: null,
            summary: null,
            summaryActive: {}
        }
    },

    computed: {
        filterActive() {
            return this.$store.state.filter.filterActive[this.page_name]
        },
        setPeriodDisabled() {
            return !this.filters.period
        },
        getRef() {
            return `infiniteLoading_${this.page_name}`
        },
        summaryTotal() {
            return this.summary?.total || 0
        },
        summaryWhite() {
            return this.summary?.white || 0
        },
        summaryYellow() {
            return this.summary?.yellow || 0
        },
        summaryOrange() {
            return this.summary?.orange || 0
        },
        summaryRed() {
            return this.summary?.red || 0
        },
    },
    methods: {
        checkAndSetShowEmpty() {
            if(this.list && !this.list.length) 
                this.showEmpty = true
            else 
                this.showEmpty = false
        },
        async getList($state) {
            try {
                this.loading = true

                let params = {
                    page: this.page,
                    page_size: this.pageSize,
                    page_name: this.page_name,
                    organizations: this.organizationsFilter,
                    ordering: this.ordering
                }

                const { data } = await this.$http.get('/risk_assessment/', {
                    params
                })
                if(data?.results?.length) {
                    this.count = data.count
                    this.list.push(... await this.setActions(data.results))
                    this.summary = data.summary
                    if(data.next) {
                        this.page += 1
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                } else {
                    $state.complete()
                    this.list = []
                    this.count = 0
                }
                this.checkAndSetShowEmpty()
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        reloadList() {
            this.page = 1
            this.list = []
            
            this.$nextTick(()=>{
                if(this.$refs[`infiniteLoading_${this.page_name}`]){
                    this.$refs[`infiniteLoading_${this.page_name}`].stateChanger.reset()
                }
            })

        },
        currentMonthFilterHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(value) {
                if(this.filters['current_year'])
                    this.filters['current_year'] = false
                if(this.filters['period']) {
                    this.filters['period'] = false
                    this.periodStart = null
                    this.periodEnd = null
                }
            }
            this.setFilters()
        },
        currentYearFilterHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(value) {
                if(this.filters['current_month'])
                    this.filters['current_month'] = false
                if(this.filters['period']) {
                    this.filters['period'] = false
                    this.periodStart = null
                    this.periodEnd = null
                }
            }
            this.setFilters()
        },
        periodHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(value) {
                if(this.filters['current_month'])
                    this.filters['current_month'] = false
                if(this.filters['current_year'])
                    this.filters['current_year'] = false
            } else {
                this.periodStart = null
                this.periodEnd = null
                this.setFilters()
            }
            if(this.periodStart && this.periodEnd)
                this.setFilters()
        },
        periodChange(date, dateStrings) {
            if(this.periodStart && this.periodEnd) {
                if(this.periodStart >= this.periodEnd) {
                    this.$message.error('Период задан некорректно!')
                    return
                }
                this.setFilters()
            }
        },
        setFilters() {
            let date_values = [],
                status_value = [],
                organizations_value = ''
            
            if(this.filters['current_month']) {
                date_values = {
                    start: this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end: this.$moment().endOf('month').format('YYYY-MM-DD')
                }
            } else if(this.filters['current_year']) {
                date_values = {
                    start: this.$moment().startOf('year').format('YYYY-MM-DD'),
                    end: this.$moment().endOf('year').format('YYYY-MM-DD')
                }
            } else if(this.filters['period']) {
                date_values = {
                    start: this.periodStart.format('YYYY-MM-DD'),
                    end: this.periodEnd.format('YYYY-MM-DD')
                }
            } else {
                date_values = []
            }

            if(this.filters['new_inquiries']) {
                status_value = ['new',]
            } else if(this.filters['processed_inquiries']) {
                status_value = ['processed',]
                
            } else {
                status_value = []
            }
            if(this.filters['organizations']) {
                organizations_value = this.organizationsFilter
            }
            eventBus.$emit(`include_fields_${this.page_name}`, {
                fields: {
                    issue_date_filter: {
                        active: this.filters['current_month'] || this.filters['current_year'] || this.filters['period'],
                        values: date_values
                    },
                    status: {
                        active: this.filters['new_inquiries'] || this.filters['processed_inquiries'],
                        values: {
                            value: status_value
                        }
                    },
                    organizations_filter: {
                        active: this.filters['organizations'],
                        values: {
                            value: organizations_value
                        }
                    },
                    total_value_filter: {
                        active: Object.keys(this.summaryActive).length !== 0,
                        values: {
                            value: Object.keys(this.summaryActive)
                        }
                    }
                }
            })
        },
    },
    created() {
        this.filtersInit()

        eventBus.$on('assessment_list_reload', () => {
            this.reloadList()
        })
        
        eventBus.$on(`update_filter_${this.model}_${this.name}`, () => {
            this.reloadList()
        })

        eventBus.$on('update_assessment_in_list', data => {
            this.updateAssessmentInList(data)
            this.getSummary()
        })

    },
    beforeDestroy() {
        eventBus.$off('assessment_list_reload')
        eventBus.$off('update_assessment_in_list')
        eventBus.$off(`update_filter_${this.model}`)

    },
}
</script>
<style lang="scss" scoped>
.summary{
    margin: 8px 8px;
    .summary_item {
        min-width: 40px;
        padding: 2px 8px;
        border-radius: 99999px;
        font-weight: 500;
        text-align: center;
        font-size: 0.875rem;
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;
        user-select: none;
        cursor: pointer;
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
        }
        &.active{
            .bg{
                opacity: 0.6;
            }
            border-color: var(--blue);
        }
        span{
            position: relative;
            z-index: 5;
        }
        &.bg_white{
            .bg{
                background-color: #eeeeee;
            }
        }
        &.bg_yellow{
            .bg{
                background-color: #ffe936;
            }
        }
        &.bg_orange{
            .bg{
                background-color: #ff9231;
            }
        }
        &.bg_red{
            .bg{
                background-color: #ff4e46;
            }
        }
    }
    .summary_item:not(:last-child) {
        height: 26px;
        margin-right: 0.5rem;
    }
}
.list-wrapper{
    &::v-deep {
        .check_button{
            .ant-checkbox-wrapper{
                margin-left: unset;
                .ant-checkbox{
                    display: none;
                    & + span{
                        padding: 2px 8px;
                        border: 1px solid var(--borderColor);
                        border-radius: 30px;
                        display: inline-block;
                        background: #e3e6ea;
                        cursor: pointer;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        user-select: none;
                        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        &:hover{
                            background: var(--primaryHover);
                        }
                    }
                    &.ant-checkbox-checked{
                        & + span{
                            background: var(--primaryHover);
                            color: var(--blue);
                        }
                    }
                }
            }
    
        }
    }
    .filters{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
        column-gap: 10px;
        padding: 8px;
    }
    .set_period {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        row-gap: 10px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .inquiries-list {
        margin-top: 8px;
        .custom_border_color {
            border-color: var(--bgColor6);
        }
    }
        .ordering {
            display: inline;
            .select{
                padding: 8px 8px;
                width: 100%;
            }
        }
        .organization-filter{
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            .ant-radio-wrapper{
                margin: 8px 8px 0 8px;
            }
        }
}
</style>